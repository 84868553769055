import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public profile$ = this.afAuth.user.pipe(map((u: any) => {
    return u ? {
      displayName: u.displayName,
      email: u.email,
      photoURL: u.photoURL,
      uid: u.uid
    } : null;
  }));
  public isOwner$ = this.profile$.pipe(map(u => u.email.indexOf('dighub.io') > -1));
  public profile: any;
  constructor(private afAuth: AngularFireAuth) {
    this.profile$.subscribe(u => this.profile = u);
  }
}
