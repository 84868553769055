import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AngularFireAuthGuard} from '@angular/fire/auth-guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'app',
    canActivate: [AngularFireAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
      },
      {
        path: 'leads',
        loadChildren: () => import('./pages/leads/leads.module').then( m => m.LeadsPageModule)
      },
      {
        path: 'posts',
        loadChildren: () => import('./pages/posts/posts.module').then( m => m.PostsPageModule)
      },
      {
        path: 'spots',
        loadChildren: () => import('./pages/spots/spots.module').then( m => m.SpotsPageModule)
      },
      {
        path: 'pages',
        loadChildren: () => import('./pages/pages/pages.module').then( m => m.PagesPageModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/users/users.module').then( m => m.UsersPageModule)
      },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
