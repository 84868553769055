import { Component } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {first} from 'rxjs/operators';
import {ProfileService} from './services/profile.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public profile$ = this.profileService.profile$;
  public isOwner$ = this.profileService.isOwner$;
  constructor(private afs: AngularFirestore, private profileService: ProfileService) {

  }
}
